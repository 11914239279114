import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Layout, Cart } from '../components'
import tw from 'tailwind.macro'

import { media } from '../styles/utils'
import { container, padding, bgImage, type } from '../styles/global'

const CartPage = props => {

    return (
        <Layout>
            <Wrapper>
                <Container>
                    <Cart />
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
    min-height: 100vh;
`

const Container = styled.div`
    ${container}
    ${padding}
    min-height: 100vh;
    padding-top: 1rem;
    padding-bottom: 6rem;

    @media (min-height: 700px) {
        ${tw`flex flex-col justify-center`};
    }
`

export default CartPage
